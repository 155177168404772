import React, { useState, useEffect, useContext } from 'react'
import { graphql } from 'gatsby'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { useInView } from 'react-intersection-observer'
import { DictionaryContext, LayoutContext } from '../contexts'
import SEO from '../components/SEO'
import ProductImages from '../components/product/ProductImages'
import ProductBasicForm from '../components/product_basic/ProductBasicForm'
import Slider from '../components/Slider'
import StickyAddToCart from '../components/product/StickyAddToCart'
import useShopifyProduct from '../hooks/checkout/useShopifyProduct'
import useSticky from '../hooks/window/useSticky'
import { desktopBreakpoint } from '../styles/index'

const ProductBasic = ({
  data: {
    contentfulProductBasicPage: {
      slug,
      seoTitle,
      seoDescription,
      title,
      productImages,
      shopifyProduct,
      variants,
      description,
      applyOn,
      relatedProducts
    }
  }
}) => {
  const dictionary = useContext(DictionaryContext)
  const { headerMenus, node_locale } = useContext(LayoutContext)
  const { lineItems } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain]
  )
  const quantity = useSelector(({ selections }) => selections.quantity)

  const [price, setPrice] = useState()
  const [productId, setProductId] = useState(null)
  const [selectedVariant, setSelectedVariant] = useState()
  const [selectedVariantSize, setSelectedVariantSize] = useState()
  const [lineItem, setLineItem] = useState(null)

  const { product } = useShopifyProduct(shopifyProduct.handle)

  const [stickyAddToCartRef, ref] = useSticky(false, true)
  const [refProduct, inViewRefProduct] = useInView({ rootMargin: '-100% 0px 0px 0px' })

  useEffect(() => {
    if (product) {
      setPrice(
        product.variants.edges[0].node.presentmentPrices.edges[0].node.price
          .amount
      )
      setProductId(product.id)
      setSelectedVariant(product.variants.edges[0].node.id)
      // setSelectedVariantSize(
      //   product.variants.edges[0].node.selectedOptions[0].value
      // )
      setLineItem(
        lineItems.edges.find(
          ({
            node: {
              variant: { id }
            }
          }) => id === product.variants.edges[0].node.id
        )
      )
    }
  }, [lineItems.edges, product])

  return (
    <Wrapper ref={ref}>
      <SEO
        isProduct
        slug={slug}
        title={seoTitle}
        description={seoDescription}
      />
      <Wrapper ref={refProduct}>
        <ProductImages productImages={productImages} review={[]} />
        <ProductBasicForm
          productForm={{ title, shopifyProduct, variants }}
          productInfo={{
            price,
            quantity,
            lineItem,
            productId,
            selectedVariant
          }}
          productAccordion={{ description }}
        />
      </Wrapper>
      {relatedProducts && (
        <Slider
          slider={{
            title: dictionary.relatedProducts,
            filters: false,
            slides: relatedProducts,
            cta: dictionary.viewAllProducts,
            ctaLink: { link: [{ slug: headerMenus[0].link[0].slug }] }
          }}
        />
      )}
      <StickyAddToCart
        ref={stickyAddToCartRef}
        show={!inViewRefProduct}
        outOfStock={false}
        stickyAddToCart={{
          title,
          shopifyProduct,
          image: productImages[0].foregroundImage
        }}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  
  @media (min-width: ${desktopBreakpoint}) {
    .swiper-wrapper {
      transform: none;
    }
  }
`

export const PageQuery = graphql`
  query ProductBasic($node_locale: String!, $id: String!) {
    contentfulProductBasicPage(id: { eq: $id }, node_locale: { eq: $node_locale }) {
      slug
      seoTitle
      seoDescription
      ...productBasicPage
      variants {
        ...productBasicPage
      }
      description {
        description
      }
      relatedProducts {
        ...productPage
      }
    }
  }
`

export default ProductBasic
