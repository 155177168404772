import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import { useSelector, useDispatch } from 'react-redux'
import Icon from '../Icon'
import useReplaceLineItem from '../../hooks/checkout/useReplaceLineItem'
import useUpdateCheckoutAttributes from '../../hooks/checkout/useUpdateCheckoutAttributes'
import useSticky from '../../hooks/window/useSticky'
import { useGtmProductDetailView, gtmAddProductToCart } from '../../hooks/useGtm'
import { DictionaryContext, StoreContext } from '../../contexts'
import { setCartOpen, setQuantity } from '../../actions'
import { SiteLink } from '../SiteLink'
import Price from '../Price'
import ProductBasicAccordion from './ProductBasicAccordion'
import FreeShipping from '../FreeShipping'
import {
  mobileVW,
  desktopVW,
  desktopBreakpoint,
  color,
  border,
  underline
} from '../../styles'

const ProductBasicForm = ({
  productForm: { title, shopifyProduct, variants },
  productInfo: {
    price,
    quantity,
    lineItem,
    productId,
    selectedVariant
  },
  productAccordion
}) => {
  const dictionary = useContext(DictionaryContext)
  const { currency } = useContext(StoreContext)
  const { storeSubdomain } = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain]
  )
  const dispatch = useDispatch()
  const [quantityInCart, setQuantityInCart] = useState(0)
  const [replaceLineItem, { loading }] = useReplaceLineItem()
  const [updateCheckoutAttributes] = useUpdateCheckoutAttributes()
  const checkout = useSelector(
    ({ storeSubdomain, checkouts }) => checkouts[storeSubdomain]
  )
  const [prodFormContentRef, prodFormRef] = useSticky(true)

  const allProdvariants = variants
    ? variants.reduce(
      (acc, { slug, shopifyProduct: shopifyProd }) => {
        acc.push({ slug, shopifyProd })

        return acc.sort((a, b) => {
          if (a.shopifyProd.variants.edges[0].node.price < b.shopifyProd.variants.edges[0].node.price) return -1
          if (a.shopifyProd.variants.edges[0].node.price > b.shopifyProd.variants.edges[0].node.price) return 1
          return 0
        })
      },
      [{ slug: null, shopifyProd: shopifyProduct }]
    )
    : [{ slug: null, shopifyProd: shopifyProduct }]

  const splitWords = title.split('|')
  const titleBigger = splitWords.shift()
  const titleSmaller = splitWords.join(' ')

  const addProductToCart = async () => {
    await replaceLineItem(
      {
        variantId: selectedVariant,
        quantity: quantityInCart + quantity
      },
      storeSubdomain
    )

    gtmAddProductToCart(currency, productId, shopifyProduct.handle, selectedVariant, price, quantity)
    dispatch(setCartOpen(true))
  }

  useEffect(() => {
    if (lineItem) {
      setQuantityInCart(lineItem.node.quantity)
    } else {
      setQuantityInCart(0)
    }
  }, [lineItem, quantityInCart])

  // useGtmProductDetailView(shopifyProduct, selectedVariantSize, productId, price)

  return (
    <div>
      <StyledProductBasicForm ref={prodFormRef}>
        <ProductBasicFormContent ref={prodFormContentRef}>
          <HeadingWrapper>
            <Title
              longText={titleBigger.length > 9}
            >
              {titleBigger}
              <div>{titleSmaller}</div>
            </Title>

          </HeadingWrapper>
          <ProductBasicAccordion productAccordion={productAccordion} />
          <SelectionWrapper>
            <SizeQuantity>
              <Size>
                <div>
                  <Price shopifyProduct={shopifyProduct} />
                  {currency}
                </div>
              </Size>
              <Quantity>
                <Counter>
                  <CounterButton
                    aria-label='decrement-by-one'
                    onClick={() =>
                      dispatch(setQuantity(Math.max(1, quantity - 1)))}
                  >
                    -
                  </CounterButton>
                  <div>{quantity}</div>
                  <CounterButton
                    aria-label='increment-by-one'
                    onClick={() => dispatch(setQuantity(quantity + 1))}
                  >
                    +
                  </CounterButton>
                </Counter>
              </Quantity>
            </SizeQuantity>

            <Variants>
              {allProdvariants.map(({ slug, shopifyProd }, index) => {
                if (slug) {
                  return (
                    <SiteLink slug={slug} key={index} disableTransition>
                      <Variant aria-label='product variant'>
                        <Price shopifyProduct={shopifyProd} noDecimal />
                        {currency}
                      </Variant>
                    </SiteLink>
                  )
                }
                return (
                  <Variant key={index} currentVariant aria-label='product variant'>
                    <Price shopifyProduct={shopifyProd} noDecimal />
                    {currency}
                  </Variant>
                )
              }
              )}
            </Variants>

            <AvailableForSale>
              <StyledButton
                aria-label='add-to-cart'
                onClick={() => addProductToCart()}
              >
                {!loading ? (
                  <>
                    <Price shopifyProduct={shopifyProduct} quantity={quantity} />
                    {currency} - {dictionary.addToCart}
                  </>
                ) : dictionary.addToCartLoading}

              </StyledButton>

              <FreeShipping animated />

            </AvailableForSale>

            <Philosophy>
              <IconWrapper>
                <Icon use name='humane' />
                <p>{dictionary.humane}</p>
              </IconWrapper>
              <IconWrapper>
                <Icon use name='vegan' />
                <p>{dictionary.vegan}</p>
              </IconWrapper>
              <IconWrapper>
                <Icon use name='inclusive' />
                <p>{dictionary.inclusive}</p>
              </IconWrapper>
            </Philosophy>
          </SelectionWrapper>

        </ProductBasicFormContent>
      </StyledProductBasicForm>
    </div>
  )
}

const StyledProductBasicForm = styled.div`
  width: 100%;
  top: 0;
  right: 50%;

  .hide-on-mobile {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    right: 0;
    width: 50%;
    height: 100%;
    position: absolute;

    .hide-on-mobile {
      display: flex;
    }

    .hide-on-desktop {
      display: none;
    }
  }
`

const ProductBasicFormContent = styled.div`
  top: 0;
  padding: ${mobileVW(55)} ${mobileVW(32)} ${mobileVW(25)};
  display: flex;
  flex-direction: column;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVW(120)} ${desktopVW(180)} 0;
  }
`

const HeadingWrapper = styled.div`
  order: 1;
`

const Title = styled.h1`
  font-size: ${({ longText }) =>
  longText ? mobileVW(52) : mobileVW(63)};
  line-height: ${mobileVW(53)};
  text-align: center;
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? mobileVW(20) : '0'};

  div {
    font-size: ${mobileVW(45)};
    line-height: ${mobileVW(40)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(45)};
    line-height: ${desktopVW(46)};
    text-align: left;
    padding-bottom: ${({ paddingBottom }) =>
      paddingBottom ? desktopVW(21) : desktopVW(20)};

    div {
      font-size: ${desktopVW(25)};
      line-height: ${desktopVW(20)};
    }
  }
`

const SelectionWrapper = styled.div`
  position: relative;
  width: 100%;
  order: 2;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(260)};
    order: 3;
  }
`

const SizeQuantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: ${border.medium};
  height: ${mobileVW(60)};
  width: 100%;
  margin: ${mobileVW(17)} auto;
  font-weight: bold;
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVW(61)};
    width: ${desktopVW(260)};
    margin: ${desktopVW(16)} 0;
    font-weight: bold;
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(10)};
  }
`

const Size = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: ${border.medium};

  div {
    display: flex;
    justify-content: center;
    align-items: baseline;
  }

  span {
    font-size: ${mobileVW(8)};
    line-height: ${mobileVW(8)};
    padding: 0 ${mobileVW(2)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    span {
      font-size: ${desktopVW(8)};
      line-height: ${desktopVW(8)};
      padding-left: ${desktopVW(2)};
    }
  }
`

const Quantity = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
`

const Counter = styled.div`
  width: ${mobileVW(76)};
  display: flex;
  justify-content: space-around;

  div {
    height: ${mobileVW(34)};
    width: ${mobileVW(34)};
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVW(76)};

    div {
      height: ${desktopVW(34)};
      width: ${desktopVW(34)};
    }
  }
`

const CounterButton = styled.button`
  flex: 1 0 auto;
`

const Variants = styled(SizeQuantity)`
  text-align: center;
  margin-top: 0 !important;

  a {
    margin: ${mobileVW(2)} ${mobileVW(8)} 0;
  }

  @media (min-width: ${desktopBreakpoint}) {
    ${({ currentVariant }) => (currentVariant ? `margin-right: ${desktopVW(5)};` : null)}

    a {
      margin: ${desktopVW(2)} ${desktopVW(8)} 0;

      button {
        margin: 0!important;
      }
    }
  }
`

const Variant = styled.button`
  position: relative;
  margin: ${mobileVW(2)} ${mobileVW(8)} 0;
  ${underline.static}

  &:after {
    visibility: ${({ currentVariant }) => currentVariant ? 'inherit' : 'hidden'};
    margin-top: ${mobileVW(2)};
  }
  
  

  @media (min-width: ${desktopBreakpoint}) {
    margin: ${desktopVW(2)} ${desktopVW(8)} 0;

    &:after {
      margin-top: ${desktopVW(2)};
    }
  }
`

const AvailableForSale = styled.div``

const StyledButton = styled.button`
  background-color: ${color.black};
  color: ${color.white};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(10)};
  width: 100%;
  height: ${mobileVW(60)};
  margin-bottom: ${mobileVW(20)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(10)};
    padding: ${desktopVW(10)};
    width: ${desktopVW(260)};
    height: ${desktopVW(61)};
    margin-bottom: ${desktopVW(20)};
  }
`

const Philosophy = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${mobileVW(20)};

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: ${desktopVW(20)};
  }
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0  ${mobileVW(20)};

  svg {
    height: ${mobileVW(20)};
    width: auto;
  }

  p {
    margin-top: ${mobileVW(4)};
    font-size: ${mobileVW(10)};
    line-height: ${mobileVW(8)};
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0  ${desktopVW(20)};

    svg {
      height: ${desktopVW(20)};
    }

    p {
      margin-top: ${desktopVW(4)};
      font-size: ${desktopVW(10)};
      line-height: ${desktopVW(8)};
    }
  }
`

export default ProductBasicForm
